import React, { useState, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useMatch } from "react-router-dom";
import axiosInstance from "../../services/axiosInstance";
import { authorizeOrder } from "../../services/bookings";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  VStack,
} from "@chakra-ui/react";

const Checkout = ({ showPreviousButton, nextStep, activeStep }) => {
  const [success, setSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const match = useMatch("/book/:bookingId");
  const bookingId = match?.params.bookingId;

  const createOrder = () => {
    return axiosInstance
      .post(`/bookings/${bookingId}/create_paypal_order`)
      .then((response) => {
        return response.data.orderId;
      })
      .catch((error) => {
        console.error("Error creating PayPal order:", error);
        throw error; // Optionally rethrow the error to handle it further up the chain
      });
  };

  const onApprove = (data) => {
    setProcessing(true);
    authorizeOrder(bookingId, data.orderID).then((response) => {
      if (response.status === 200) {
        setProcessing(false);
        setSuccess(true);
      } else {
        setProcessing(false);
        alert("Error authorizing order with PayPal");
      }
    });
  };

  useEffect(() => {
    if (success) {
      showPreviousButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <PayPalScriptProvider
      options={{
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: "EUR",
        intent: "authorize",
      }}
    >
      {!success && !processing && (
        <div>
          <br></br>
          <PayPalButtons
            style={{ layout: "vertical" }}
            createOrder={createOrder}
            onApprove={onApprove}
          />
        </div>
      )}
      {processing && (
        <div>
          <Alert
            borderRadius="lg"
            status="info"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Processing payment authorization...
            </AlertTitle>
            <AlertDescription fontSize={"sm"}>
              Please wait while we process your payment authorization.
            </AlertDescription>
          </Alert>
        </div>
      )}

      {success && (
        <div>
          {
            <VStack>
            <Alert
              borderRadius="lg"
              status="success"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              maxHeight="400px"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Reservation completed!
              </AlertTitle>
              <AlertDescription fontSize={"sm"}>
                Thanks for submitting your reservation.
                Our team will get back to you soon via email and WhatsApp.
              </AlertDescription>
            </Alert>

            <Alert
            borderRadius="lg"
            status="info"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            maxHeight="400px"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Reservation confirmation
              </AlertTitle>
              <AlertDescription fontSize={"sm"} flexWrap={"wrap"}>
                A 20% deposit of the total amount is currently pending confirmation.
                If the reservation cannot be confirmed, the deposit will be automatically refunded.
                Please stay tuned for an email and WhatsApp message with further updates.
              </AlertDescription>
            </Alert>
            </VStack>



          }
        </div>
      )}
    </PayPalScriptProvider>
  );
};

export default Checkout;
