import React from "react";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

function BookingDetailsModal({ tour, accommodationUnits, accommodation }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Helper function to format dates
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Array of day abbreviations
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    // Extract the day of the week, day, and year
    const dayOfWeek = days[date.getDay()];
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are 0-indexed
    const year = date.getFullYear();

    // Construct the formatted date string
    return `(${dayOfWeek})${day}/${month}/${year}`;
  };

  // Responsive table layout based on screen size
  const tableVariant = useBreakpointValue({
    base: "simple",
    md: "striped",
  });

  const TourTable = ({ tour }) => {
    return (
      <Box p={4} maxW="1200px" mx="auto">
        <Text fontSize="2xl" fontWeight="bold" mb={4}>
          Tour Details
        </Text>

        <Table variant={tableVariant} mb={8}>
          <Thead>
            <Tr>
              <Th colSpan={2}>
                <Text fontSize="xl" fontWeight="semibold">
                  {tour?.name}
                </Text>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td fontWeight="bold">Cities:</Td>
              <Td>{tour?.cities.join(", ")}</Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">Pickup Location:</Td>
              <Td>{tour?.pickupLocation}</Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">Selected Vehicle:</Td>
              <Td>
                {tour?.selectedVehicleCategory.name} - EUR
                {tour?.selectedVehicleCategory.price} (
                {tour?.selectedVehicleCategory.seats} seats)
              </Td>
            </Tr>
          </Tbody>
        </Table>

        <Text fontSize="sm" fontWeight="bold" mb={1}>
          Accommodation Units
        </Text>
        <Table variant={tableVariant} size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Quantity</Th>
              <Th>Total Price</Th>
              <Th>Start Date</Th>
              <Th>End Date</Th>
              <Th>Accommodation</Th>
            </Tr>
          </Thead>
          <Tbody>
            {tour?.accommodationUnits?.map((unit, index) => (
              <Tr key={index}>
                <Td>{unit?.name}</Td>
                <Td>{unit?.quantity}</Td>
                <Td>{unit?.totalPrice}</Td>
                <Td>{formatDate(unit?.startDate)}</Td>
                <Td>{formatDate(unit?.endDate)}</Td>
                <Td>
                  {unit?.stay.name} ({unit?.stay.type})<br />
                  {unit?.stay.address}, {unit?.stay.city}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    );
  };

  const AccommodationsTable = ({ units, accommodation }) => {
    return (
      <Box p={4} maxW="1200px" mx="auto">
        <Text fontSize="2xl" fontWeight="bold" mb={4}>
          Accommodation: {accommodation?.name}
        </Text>

        <Text fontSize="sm" fontWeight="bold" mb={1}>
          Accommodation Units
        </Text>
        <Table variant={tableVariant} size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Quantity</Th>
              <Th>Total Price</Th>
              <Th>Start Date</Th>
              <Th>End Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {units?.map((unit, index) => (
              <Tr key={index}>
                <Td>{unit?.name}</Td>
                <Td>{unit?.quantity}</Td>
                <Td>{unit?.totalPrice}</Td>
                <Td>{formatDate(unit?.startDate)}</Td>
                <Td>{formatDate(unit?.endDate)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    );
  };

  return (
    <>
      <Button onClick={onOpen}>Click to view booking details</Button>

      <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Box overflowX={"auto"} flexWrap={"wrap"}>
              {tour ? (
                <TourTable tour={tour} />
              ) : (
                <AccommodationsTable
                  units={accommodationUnits}
                  accommodation={accommodation}
                />
              )}
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default BookingDetailsModal;
